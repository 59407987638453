import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { BusinessNavigation } from "./components/Business";
import {
  Calendar,
  AddBranch,
  Bookings,
  BusinessSeting,
  Clients,
  Error404,
  Login,
  SignUp,
  ViewClient,
  RollManagement,
  Dashboard,
  Notification,
  Branch,
  AssignServicesToBusiness,
  TeamTitle,
  Promotion,
  AddPromotion,
  Setting,
  Reports,
} from "./pages/Business";
import ResetPassword from "./pages/Business/Login/ResetPassword";
import UpdateBusinessAdmin from "./pages/AdminRedirect";
import { useSelector } from "react-redux";
import Business404 from "./pages/Business/Error404/Business404";
import ChatBox from "./pages/Business/Chat/ChatBox";
import WellnezzaAddPromotion from "./pages/Business/Promotion/WellnezzaAddPromotion";
import AssignServicesToTeam from "./pages/Business/AssignServicesToTeam";
import RolesList from "./pages/Business/RolesList";
import TeamVerification from "./pages/Business/TeamVerification/TeamVerification";
import AllServices from "./pages/Business/AllServices";
import Wellnezza from "./pages/Business/Wellnezza/Wellnezza";
import Publish from "./pages/Business/Publish";

const App = () => {
  let { mainModuleRoles, subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const businessData = localStorage.getItem('business_data') ? JSON.parse(localStorage.getItem('business_data')!) : null

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/business/login" replace />} />
      <Route path="*" element={<Error404 />} />
      <Route path="/updateBusinessinfo/:UID/:BID/:TOKEN" element={<UpdateBusinessAdmin />} />
      <Route path="business">
        <Route path="team-verification" element={<TeamVerification />} />
        <Route path="" element={<StopRedirection />}>
          <Route path="signup" element={<SignUp />} />
          <Route path="login" element={<Login />} />
          <Route path="resetPassword/:uid" element={<ResetPassword />} />
        </Route>
        <Route path="" element={<BusinessNavigation />}>
          {+businessData?.stepCompleted > 9 && <Route index element={<Navigate to="dashboard/*" />} />}
          {+businessData?.stepCompleted <= 6 && <Route index element={<Navigate to="branch" />} />}
          {+businessData?.stepCompleted === 7 && <Route index element={<Navigate to="services" />} />}
          {+businessData?.stepCompleted === 8 && <Route index element={<Navigate to="new-teams" />} />}
          {+businessData?.stepCompleted === 9 && <Route index element={<Navigate to="wellnezza/business" />} />}
          <Route path="*" element={<Business404 />} />

          {/* main modules  */}
          {mainModuleRoles?.Dashboard?.View && (
            <Route path="dashboard" element={<Navigate to="dashboard/today" />} />)}

          {mainModuleRoles?.Dashboard?.View && (<Route path="dashboard/*" element={<Dashboard />} />)}

          {(mainModuleRoles?.Bookings?.ViewOwnBookings || mainModuleRoles?.Bookings?.ViewAllBookings) && <Route path="calendar" element={<Calendar />} />}
          {(mainModuleRoles?.Bookings?.ViewOwnBookings || mainModuleRoles?.Bookings?.ViewAllBookings) && <Route path="bookings" element={<Bookings />} />}
          {(mainModuleRoles?.Reports?.Bookings || mainModuleRoles?.Reports?.Payment || mainModuleRoles?.Reports?.Team || mainModuleRoles?.Reports?.Promotion || mainModuleRoles?.Reports?.Branch) && <Route path="Reports" element={<Reports />} />}

          {mainModuleRoles?.Customer?.View && <Route path="clients" element={<Clients />} />}
          {mainModuleRoles?.Customer?.View && <Route path="clients/addClient" element={<Clients />} />}
          {mainModuleRoles?.Customer?.View && <Route path="clients/edit-client" element={<Clients />} />}
          {mainModuleRoles?.Customer?.View && <Route path="clients/view-client" element={<ViewClient />} />}

          {mainModuleRoles?.Promotion?.View && <Route path="promotion" element={<Promotion />} />}
          {mainModuleRoles?.Promotion?.View && <Route path="promotion/addPromotion/*" element={<AddPromotion />} />}
          {mainModuleRoles?.Promotion?.View && <Route path="promotion/WellnezzaAddPromotion/*" element={<WellnezzaAddPromotion />} />}
          {mainModuleRoles?.Promotion?.View && <Route path="promotion/edit-promotion/*" element={<AddPromotion />} />}
          {mainModuleRoles?.Promotion?.View && <Route path="promotion/wellnezza-edit-promotion/*" element={<WellnezzaAddPromotion />} />}

          {subModuleRoles?.["Business Set Up"]?.["Business Home"]?.View && <Route path="wellnezza/*" element={<Wellnezza />} />}

          {mainModuleRoles?.Publish?.View && <Route path="publish" element={<Publish />} />}
          {subModuleRoles?.['Services']?.Services?.View && <Route path="publish/addService/*" element={<Publish />} />}
          {subModuleRoles?.['Services']?.Services?.View && <Route path="publish/Edit-service/*" element={<Publish />} />}

          {subModuleRoles?.['Services']?.Services?.View && <Route path="publish/add-bundle/*" element={<Publish />} />}
          {subModuleRoles?.['Services']?.Services?.View && <Route path="publish/edit-bundle/*" element={<Publish />} />}

          {subModuleRoles?.['Teams']?.Team?.View && <Route path="publish/addTeams/*" element={<Publish />} />}
          {subModuleRoles?.['Teams']?.Team?.View && <Route path="publish/edit-team/*" element={<Publish />} />}

          {(subModuleRoles?.['Services']?.Services?.View || subModuleRoles?.['Services']?.['Service Category']?.View || subModuleRoles?.['Services']?.Bundles.View) && <Route path="services" element={<AllServices />} />}
          {(subModuleRoles?.['Services']?.Services?.View || subModuleRoles?.['Services']?.['Service Category']?.View || subModuleRoles?.['Services']?.Bundles.View) && <Route path="services/addService/*" element={<AllServices />} />}
          {(subModuleRoles?.['Services']?.Services?.View || subModuleRoles?.['Services']?.['Service Category']?.View || subModuleRoles?.['Services']?.Bundles.View) && <Route path="services/Edit-service/*" element={<AllServices />} />}

          {(subModuleRoles?.['Services']?.Services?.View || subModuleRoles?.['Services']?.['Service Category']?.View || subModuleRoles?.['Services']?.Bundles.View) && <Route path="services/add-bundle/*" element={<AllServices />} />}
          {(subModuleRoles?.['Services']?.Services?.View || subModuleRoles?.['Services']?.['Service Category']?.View || subModuleRoles?.['Services']?.Bundles.View) && <Route path="services/edit-bundle/*" element={<AllServices />} />}

          {subModuleRoles?.['Services']?.['Assign Branch']?.View && <Route path="assign-services-to-branches" element={<AssignServicesToBusiness />} />}

          {(subModuleRoles?.['Teams']?.Team?.View || subModuleRoles?.['Teams']?.['Team Title']?.View) && <Route path="new-teams" element={<TeamTitle />} />}
          {(subModuleRoles?.['Teams']?.Team?.View || subModuleRoles?.['Teams']?.['Team Title']?.View) && <Route path="new-teams/addTeams/*" element={<TeamTitle />} />}
          {(subModuleRoles?.['Teams']?.Team?.View || subModuleRoles?.['Teams']?.['Team Title']?.View) && <Route path="new-teams/edit-team/*" element={<TeamTitle />} />}

          {subModuleRoles?.['Teams']?.['Assign Services']?.View && <Route path="assign-services-to-teams" element={<AssignServicesToTeam />} />}
          {subModuleRoles?.['Teams']?.['WN APP Access']?.View && <Route path="roles-list" element={<RolesList />} />}


          {/* Business sub menu  */}
          {subModuleRoles?.['Business Set Up']?.['Business Home']?.View && <Route path="business-setting/basic/*" element={<BusinessSeting />} />}

          {subModuleRoles?.['Business Set Up']?.['Branch/Location']?.View && <Route path="branch" element={<Branch />} />}
          {subModuleRoles?.['Business Set Up']?.['Branch/Location']?.View && <Route path="branch/add-branch/*" element={<AddBranch />} />}
          {subModuleRoles?.['Business Set Up']?.['Branch/Location']?.View && <Route path="branch/edit-branch/*" element={<AddBranch />} />}

          {subModuleRoles?.['Business Set Up']?.['User Access']?.View && <Route path="roll-management" element={<RollManagement />} />}

          <Route path="notification" element={<Notification />} />
          <Route path="error-404" element={<Error404 />} />

          {mainModuleRoles?.Chat?.View && <Route path="chat" element={<ChatBox />} />}

          <Route path="setting" element={<Setting />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;

const StopRedirection = () => {
  const admin_token = localStorage.getItem("user_token");
  const user_data = localStorage.getItem("user_data");
  let userdata;
  if (user_data) {
    userdata = JSON.parse(user_data);
  }
  let authGaurd = (userdata?.emailVerified && userdata?.stepCompleted >= 5 && admin_token);
  return <>
    {!authGaurd ? (
      <div>
        <Outlet />
      </div>
    ) : (
      <Navigate to="/business"></Navigate>
    )}
  </>
}
